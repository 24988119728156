<template>
  <div @click="dialogVisible=true">
    <slot></slot>
    <template v-if="mode === 'show'">
      <el-select disabled style="width: 100%" v-model="area_choose" multiple>
        <el-option v-for="(v,k) in area_list" :key="k" :value="k" :label="v"></el-option>
      </el-select>
    </template>
    <el-dialog custom-class="custom" append-to-body title="选择区域" :show-close="false" :visible.sync="dialogVisible" width="50rem">
      <div style="padding: 0 5rem ">
        <el-select style="width: 100%" v-model="area_choose" multiple>
          <el-option v-for="(v,k) in area_list" :key="k" :value="k" :label="v"></el-option>
        </el-select>
      </div>
      <template #footer>
        <el-button @click="dialogVisible=false">取消</el-button>
        <el-button @click="confirm" type="primary">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
const area = require("china-area-data/data.json")
export default {
  name: "y_choose_area",
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal: {
      type:String,
      default:""
    },
    mode:{
      type:String,
      default: ""
    }
  },
  watch:{
    modelVal(){
      this.area_choose = this.modelVal.split(",");
    }
  },
  data(){
    return{
      area_list : area[86],
      area_choose:[],

      value: this.modelVal,
      dialogVisible:false,

    }
  },
  mounted() {
    if(this.modelVal){
      this.area_choose = this.modelVal.split(",");
    }
  },
  methods:{
    confirm(){
      this.$emit('change',this.area_choose.join(","));
      this.dialogVisible = false;
    },
  }
}
</script>
<style>
.custom>.el-dialog__body{
  padding: 0 20px 30px !important;
  margin: 0;
}
</style>
<style scoped>

</style>